<template>
  <div class="container">
    <SimpleHeader />
    <van-form @submit="onSubmit">
    <van-cell-group>
      <van-field v-model="mobile" name="mobile" type="tel" label="账号" placeholder="请输入账号" clearable :rules="[{ required: true, message: '请输入登录手机号' }]" />
      <van-field v-model="password" name="password" type="password" label="新密码" placeholder="请输入新密码" clearable :rules="[{ required: true, message: '请输入新密码' }]" />
      <van-field v-model="rePassword" name="rePassword" type="password" label="确认密码"  placeholder="再次请输入新密码" clearable :rules="[{ validator: validatorPassword }]"  />
      <div class="info">密码至少6个字符，可包含数字和字母</div>
      <van-field
        v-model="code"
        type="number"
        name="code"
        center
        clearable
        label="验证码"
        placeholder="请输入短信验证码"
        :rules="[{ required: true, message: '请输入短信验证码' }]"
      >
        <template #extra>
            <template v-if="show_send_code">
              <div class="login-send" @click="sendCode">
                发送验证码
              </div>
            </template>
            <template v-else>
              <div class="login-send--a">
                <van-count-down :time="time" format="ss" @finish="timeFinish"/>
                秒后重新发送
              </div>
            </template>

          </template>
      </van-field>
    </van-cell-group>
    <div class="global-button" :class="isValidator?'ok':''">
        <van-button block type="primary" native-type="submit">
          完成
        </van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import SimpleHeader from '@/components/SimpleHeader'
import { onMounted,reactive,toRefs,inject,computed } from 'vue'
import { changePassword,sendSms } from '@/api/user'
 import to from 'await-to-js';
import { useStore } from 'vuex';
import router from '../../router';
import {Toast} from 'vant'
export default {
  name: 'password',
  components: {
    SimpleHeader
  },
  setup() {
    const _debounce = inject('debounce');
    const TIME = 60 * 1000;
    const store = useStore()
    const state = reactive({
      mobile:'',
      password:'',
      rePassword:'',
      code:'',
      show_send_code:false,
      time:0
    })
    const validatorPassword = (val) => {
      if(!val) return '请输入确认密码'
      return val === state.password?'':'两次密码不一致'
    };
    const sendCode = async () => {
      if (state.time === TIME) {
        return false;
      }
      let mobile = state.mobile;
      if (!mobile) {
        Toast('请输入账号');
        return false;
      }
      mobile = mobile.trim();
      let [err, res] = await to(sendSms({
        mobile
      }))
      if(err) return
      if (res) {
        Toast(res.msg || "验证码发送成功");
        state.show_send_code = false;
        state.time = TIME;
      }
    }
    const timeFinish = () => {
      state.show_send_code = true;
      state.time = 0;
    }

    const updatePassword = async() => {
      let params = {
        mobile:state.mobile,
        password:state.password,
        code:state.code,
      }
      let [error,result] = await to(changePassword(params))
      if(error) return;
      let {code, res, msg} = result;
      if (code == 0) {
        Toast(msg || "密码修改成功");
        router.push('/login')
      }
    }
    let isValidator = computed(()=>{
      if(!state.mobile) return false
      if(!state.password) return false
      if(!state.rePassword) return false
      if(state.password !== state.rePassword) return false
      if(!state.code) return false
      return true
    })
    const onSubmit = _debounce(updatePassword,300,true)

    onMounted(async () => {
    })

    return {
      ...toRefs(state),
      sendCode,
      timeFinish,
      onSubmit,
      validatorPassword,
      isValidator
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../common/style/base/base';
.container {
  padding: 0;
  min-height: 100%;
}
 .login-send {
    background: #000;
    height: 24px;
    line-height: 24px;
    .fontcolor(11px, #fff);
    padding: 0 10px;
    .borderRadius(12px)
  }
   .login-send--a {
    background: @btnBg;
    .disFlexA();
    .fontcolor(11px, @basicTextColor);
    height: 24px;
    line-height: 24px;
    padding: 0 10px;
    .borderRadius(12px)
  }
.global-button {
    background: #BBBBBB;;
    color: #ffffff;
    font-weight: bold;
    margin: 50px 0.4667rem 0;
    &:deep(.van-button--primary){
      background: #BBBBBB;
      color: #ffffff;
    }
    &.ok{
      background: #000000;
      &:deep(.van-button--primary){
        background: #000000;
        color: #ffffff;
    }
    }
  }
  .info{
    padding: 10px 15px;
    .sc(12px,#999)
  }
  .van-cell{
  padding: 13px 16px;
}
</style>